<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ThePreloader',
  setup () {
    return {
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <svg class="spinner" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 181">
          <path d="M1 1c14.9824 1.52017 46.6234 14.5936 53.3285 54.7261 8.3814 50.1659 51.1745-7.8305 76.8115-5.4362 25.637 2.3942-85.1298 94.5231-76.8115 108.2581 5.6803 9.379 40.0991-6.253 41.7425 21.452" stroke="currentColor" class="c-gold progress-preloader" stroke-linecap="round" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: $z-loader;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c-dark-grey;
  color: $c-gold;
}

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: rem(150px);
  height: rem(150px);
  transform: translate(-50%, -50%);
}

.progress-preloader {
  animation: dash 2s linear infinite;
  stroke-dasharray: 370;
  stroke-dashoffset: -370;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 370;
  }

  100% {
    stroke-dashoffset: -370;
  }
}
</style>
